* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  // user-select: none;
  scroll-behavior: smooth;
}

:root {
  --containerOffset: 35px;
  --borderRadius: 30px;
  --text-ff: "RF Dewi";
  --main-ff: "RF Dewi Expanded";
  --secondary-ff: "Firs Neue";
  --white: #ffffff;
  --gray: #d9d9d9;
  --olive: #b1c346;
  --black: #171717;
  --blue: #4946c3;
}

#root {
  position: relative;
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  font-family: var(--main-ff);
  outline: none;
  border: none;
}

input,
textarea {
  outline: none;
  font-family: var(--main-ff);
  font-size: 1rem;
}

textarea {
  resize: none;
}

.phrase:nth-child(1) {
  transition-delay: 0.1s !important;
}

.phrase:nth-child(2) {
  transition-delay: 0.5s !important;
}

.phrase:nth-child(3) {
  transition-delay: 0.9s !important;
}

input:focus,
textarea:focus {
  outline: none;
}

body,
html {
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 8px;
  background-color: #f1f1f1;
}

::-webkit-scrollbar-track {
  background-color: var(--black);
}

/* Customize the scrollbar handle */
::-webkit-scrollbar-thumb {
  background-color: #e0e0e0;
  border-radius: 4px;
}

/* Hover style for the scrollbar handle */
::-webkit-scrollbar-thumb:hover {
  background-color: #ffffff;
}

body {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  font-family: var(--main-ff);
  background: var(--white);
  color: var(--black);
}

.animate {
  transform: translate(0) !important;
  opacity: 1 !important;
}

#paint0_radial_412_2 {
  animation: rotateGradient 5s linear infinite;
}

@keyframes rotateGradient {
  0% {
    transform: translate(119.5px, 82.5px) rotate(0deg) scale(89.2216, 126.257);
  }
  100% {
    transform: translate(119.5px, 82.5px) rotate(360deg) scale(89.2216, 126.257);
  }
}

.button {
  width: 180px;
  height: 180px;
  font-size: 2.1875rem;
  font-style: normal;
  font-weight: 800;
  line-height: 98%;
  text-transform: uppercase;
  border-radius: 50%;
  flex-shrink: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  &__text {
    position: relative;
  }
  transition: 0.3s;
  &:hover {
    transform: rotate(3deg);
  }
}

.title {
  font-size: 1.7rem;
  font-style: normal;
  font-weight: 400;
  line-height: 78.5%;
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  text-align: left;
  font-family: var(--secondary-ff);
  &::before {
    position: absolute;
    content: "";
    left: calc(-1 * var(--containerOffset));
    width: 100vw;
    height: 2px;
    bottom: 0;
    background: var(--black);
  }
}

.container {
  max-width: 1920px;
  padding: 0 var(--containerOffset);
  margin: 0 auto;
  width: 100%;
}

.header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background: var(--white);
  color: var(--black);
  z-index: 1000;
  &__inner {
    width: 100%;
    height: 110px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__logo {
    font-size: 5.5rem;
    line-height: 5.5rem;
    font-weight: 900;
    padding-bottom: 10px;
    cursor: pointer;
    width: 400px;
  }
  &__menu {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 50px;
    gap: 5px;
    &__line {
      width: 100%;
      height: 7px;
      background: var(--black);
    }
  }
}

.menu {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1001;
  border-radius: 20px;
  border: 3px solid var(--black);
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: flex-start;
  background: rgba(255, 255, 255, 0.9);
  transition: 0.5s;
  padding: 30px !important;
  transform: translate(110%, -20%) scale(0.5);
  &__burger {
    position: fixed;
    top: 36px;
    right: 31px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 50px;
    gap: 5px;
    transition: 0.6s;
    &__line {
      width: 100%;
      height: 7px;
      background: var(--black);
      transition: 0.6s;
    }
  }
  &__services {
    display: flex;
    flex-direction: column;
    gap: 40px;
    &__title {
      font-size: 1.875rem;
      font-style: normal;
      font-weight: 800;
      line-height: 98%;
    }
    &__list {
      display: flex;
      align-items: flex-start;
      gap: 40px;
      &__column {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 40px;
        &__item {
          cursor: pointer;
          position: relative;
          padding-left: 30px;
          font-size: 1.25rem;
          font-style: normal;
          font-weight: 700;
          line-height: 98%;
          &::before {
            position: absolute;
            content: "";
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            width: 14px;
            height: 14px;
            transition: 0.3s;
            // background: url(../images/menuListIcon.svg) no-repeat center center / contain;
          }
          &:hover::before {
            transform: translateY(-50%) translateX(-3px);
          }
        }
      }
    }
  }
  &__contacts {
    display: flex;
    flex-direction: column;
    gap: 40px;
    align-items: flex-start;
    &__line {
      display: flex;
      align-items: flex-start;
      gap: 15px;

      &__icon {
        margin-top: 1px;
        width: 20px;
        height: 20px;
      }
      &__text {
        font-family: var(--text-ff);
        font-weight: 300;
        font-size: 1.125rem;
      }
    }
    &__title {
      font-size: 1.875rem;
      font-style: normal;
      font-weight: 800;
      line-height: 98%;
    }
  }
}

.menuActive {
  transform: translate(0, 0) scale(1);
  .menu__burger {
    &__line {
      &:nth-child(1) {
        transform: translate(-1px, 11px) rotate(45deg);
      }
      &:nth-child(2) {
        opacity: 0;
      }
      &:nth-child(3) {
        transform: translate(-1px, -11px) rotate(-45deg);
      }
    }
  }
}

.animateposter {
  transform: translate(0) rotate(-4.542deg) !important;
  opacity: 1 !important;
}

.banner {
  position: relative;
  overflow: hidden;
  background: var(--white);
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  &__bg {
    margin-top: 30px;
    width: 100%;
    height: 435px;
    background: url(../images/introBg.png) no-repeat top center / contain;
  }
  &__info {
    display: flex;
    justify-content: space-between;
    height: 320px;
    align-self: flex-start;
    width: 100%;
  }
  margin-top: 120px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 2;
  &__poster {
    white-space: nowrap;
    position: absolute;
    z-index: -1;
    left: 34%;
    top: 120px;
    border-radius: 15px;
    border: 3px solid var(--black);
    background: var(--olive);
    font-family: var(--main-ff);
    font-size: 3.125rem;
    font-style: normal;
    font-weight: 800;
    line-height: 98%;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    padding: 20px;
    text-transform: none;
    opacity: 0;
    transform: translateY(-100px) rotate(10deg);
    transition: 0.5s;
    transition-delay: 0.8s;
  }
  &__title {
    .word {
      transition: 0.8s;
      opacity: 0;
    }
    .word:nth-child(2) {
      transition-delay: 0.1s;
      transform: translateY(50px);
      padding-left: 250px;
    }
    .word:nth-child(3) {
      transition-delay: 0.3s;
      transform: translateY(50px);
    }
    .word:nth-child(4) {
      transition-delay: 0.5s;
      transform: translateY(50px);
    }
    position: relative;
    z-index: 1;
    color: var(--black);
    font-family: var(--secondary-ff);
    text-transform: capitalize;
    font-weight: 400;
    font-size: 12.625rem;
    line-height: 80%;
    transition: 0.8s;

    &__icon {
      position: absolute;
      top: 0;
      left: 80px;
      width: 120px;
      height: 120px;
    }
  }
  &__right {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    &__button {
      &__container {
        width: 80px;
        height: 80px;
        padding: 5px;
        flex-shrink: 0;
        cursor: pointer;
        pointer-events: all;
        transition: 0.3s;
        animation: iconRotate 3s linear infinite;
        @keyframes iconRotate {
          0% {
            transform: rotate(10deg);
          }
          50% {
            transform: rotate(-10deg);
          }
          100% {
            transform: rotate(10deg);
          }
        }
        opacity: 0;
        transition: 0.8s;
        background: var(--white);
        border-radius: 50%;
        &:hover {
          box-shadow: inset 0 0 0 3px var(--blue);
        }
      }
      width: 100%;
      height: 100%;

      &::before {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url(../images/introButtonBg.png) no-repeat center center / cover;
        animation: rotate 15s linear infinite;
        @keyframes rotate {
          from {
            transform: rotate(0);
          }
          to {
            transform: rotate(360deg);
          }
        }
      }
    }
    &__text {
      font-size: 1.3rem;
      line-height: 95%;
      font-size: 18px;
      transition: 0.4s;
      transition-delay: 0.4s;
      width: 360px;
      align-self: flex-end;
      opacity: 0;
      transform: translateY(50px);
    }
  }
}

.services {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 7;
  &::after {
    position: absolute;
    content: "";
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background: rgba(0, 0, 0, 0.8);
    border-radius: 0 0 60px 60px;
  }
  &__upper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 60px 0;
    &__title {
      padding-left: 200px;
      padding-right: 100px;
      font-size: 100px;
      line-height: 78.5%;
      letter-spacing: -1.74px;
      font-family: var(--secondary-ff);
      transform: translateY(50px);
      transition: 0.8s;
      opacity: 0;
      transition-delay: 0.2s;
    }
    &__quote {
      transform: translateY(50px);
      transition: 0.4s;
      transition-delay: 0.8s;
      opacity: 0;
      gap: 60px;
      border-radius: 30px;
      border: 2px solid var(--white);
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 50%;
      &__text {
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 98%;
        width: 210px;
      }
      &__spinner {
        width: 230px;
        height: 230px;
        animation: spinnerAnim 10s linear infinite;
        @keyframes spinnerAnim {
          from {
            transform: rotate(0);
          }
          to {
            transform: rotate(360deg);
          }
        }
      }
    }
  }
  &__list {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    z-index: 5;
    &__item:not(:last-child) {
      width: 110%;
      margin-right: -20px;
    }
    &__item:last-child {
      border-radius: 25px;
    }
    &__item {
      height: 350px;
      border-radius: 25px 0 0 25px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 20px;
      padding: 50px 40px 25px;
      transition: 0.6s;
      transform: translateX(100px);
      border-radius: 30px;

      box-shadow: 0 0 0 1px var(--black);
      position: relative;
      background: var(--white);
      &:hover {
        margin-top: -10px;
      }

      &:hover .services__list__item__title {
        transform: translateY(-5px);
      }
      &:hover .services__list__item__icon__container {
        transform: translateY(-2px) rotate(135deg);
      }
      &:nth-child(1) {
        transition-delay: 0.1s;
      }
      &:nth-child(2) {
        transition-delay: 0.2s;
      }
      &:nth-child(3) {
        transition-delay: 0.3s;
      }
      &:nth-child(4) {
        transition-delay: 0.4s;
      }
      &__title {
        font-family: var(--secondary-ff);
        font-style: normal;
        font-weight: 400;
        font-size: 36px;
        line-height: 78.5%;
        letter-spacing: 0.3px;
        cursor: pointer;
        transition: 0.5s;
        pointer-events: none;
      }
      &__icon {
        fill: var(--white);
        &__container {
          transition: 0.3s;
          flex-shrink: 0;
          width: 90px;
          height: 90px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          background: var(--black);
          pointer-events: none;
        }
      }
    }
  }
  &__lower {
    position: relative;
    z-index: 4;
    margin-top: -120px;
    overflow: hidden;
    color: var(--white);

    &:before {
      position: absolute;
      width: 100%;
      height: 100%;
      content: "";
      background: var(--black);
      z-index: -1;
      top: 0;
      left: 0;
    }
    &:after {
      position: absolute;
      width: 100%;
      height: 100%;
      content: "";
      background: url(../images/servicesBg.png) no-repeat center center / cover;
      z-index: -1;
      top: 0;
      left: 0;
    }
    // background: url(../images/servicesBg.png), black -40px 104.247px / 130.342% 104.055% no-repeat;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 240px 140px 140px;
    gap: 60px;
    &__title {
      font-size: 9.375rem;
      font-style: normal;
      font-weight: 600;
      line-height: 78.5%;
      font-family: var(--secondary-ff);
      position: relative;
      z-index: 2;
    }
    &__text {
      font-size: 72px;
      font-style: normal;
      font-weight: 400;
      line-height: 78.5%;
      letter-spacing: -3.6px;
      position: relative;
      z-index: 2;
      width: 585px;
      &__icon {
        position: absolute;
        left: -100px;
        top: 0;
        width: 71px;
        height: 67px;
      }
    }
    &__row {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      margin-top: 80px;
      &__item {
        display: flex;
        flex-direction: column;
        gap: 35px;
        width: 280px;
        &__number {
          font-size: 10.75rem;
          font-style: normal;
          font-weight: 400;
          line-height: 92.5%;
          letter-spacing: -7.74px;
          transform: translateY(50px);
          transition: 0.8s;
          opacity: 0;
          transition-delay: 0.3s;
          &.secondnumber {
            transition-delay: 0.6s;
          }
        }
        &__text {
          font-size: 1.875rem;
          font-style: normal;
          font-weight: 400;
          line-height: 98%;
        }
        &__symbol {
          &__container {
            width: 220px;
            height: 150px;
            display: flex;
            justify-content: center;
            align-items: center;
            transform: translateY(80px);
            transition: 0.8s;
            opacity: 0;
            border-radius: 50%;
            transition-delay: 0.9s;
          }
        }
      }
    }
  }
}

.dev {
  border-radius: 0 0 60px 60px;
  position: relative;
  z-index: 7;
  color: var(--black);
  padding-bottom: 120px;

  &__inner {
    display: flex;
    flex-direction: column;
  }
  &__upper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    &__title {
      transform: translateY(50px);
      transition: 0.8s;
      opacity: 0;
      align-self: flex-start;
    }
    width: 100%;
    &__image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      &__container {
        margin-top: -100px;
        transform: translateY(100px);
        height: 500px;
        transition: 0.8s;
        opacity: 0;
        position: relative;
        z-index: 8;
        width: 930px;

        flex-shrink: 0;
        right: calc(-1 * var(--containerOffset));
      }
    }
  }
  &__row {
    display: flex;
    align-items: flex-start;
    gap: 30px;
    width: 100%;
  }
  &__column {
    &:nth-child(1) {
      transform: translateY(50px);
      transition: 0.8s;
      transition-delay: 0.5s;
      opacity: 0;
    }
    &:nth-child(2) {
      transform: translateY(50px);
      transition: 0.8s;
      transition-delay: 1s;

      opacity: 0;
    }
    position: relative;
    padding: 45px 60px;
    width: calc(100% - 15px);
    min-height: 765px;
    &__button {
      position: absolute;
      bottom: -120px;
      right: 100px;

      border: 4px solid var(--black);
      background: var(--olive);
    }
    &__title {
      font-size: 4.5rem;
      font-style: normal;
      font-weight: 700;
      line-height: 78.5%;
      letter-spacing: -3.6px;
    }
    &__text {
      margin: 40px 0 55px;
      font-family: var(--text-ff);
      font-size: 1.125rem;
    }
    &__services {
      display: flex;
      flex-direction: column;
      gap: 40px;

      &__title {
        font-size: 5rem;
        font-style: normal;
        font-weight: 700;
        line-height: 78.5%;
        letter-spacing: -3.6px;
      }
      &__list {
        display: flex;
        justify-content: space-between;
        &__column {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 30px;
          &__item {
            display: flex;
            align-items: center;
            position: relative;
            padding-left: 40px;
            font-family: var(--text-ff);
            font-weight: 600;
            font-size: 1.125rem;
            &::before {
              position: absolute;
              content: "";
              top: -3px;
              left: 0;
              background: url(../images/developmentListIcon.png) no-repeat center center / cover;
              width: 30px;
              height: 30px;
            }
          }
        }
      }
    }
  }
}

.design {
  position: relative;
  z-index: 6;
  color: var(--white);
  background: var(--black);
  padding: 40px 0 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  &__inner {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0 auto;
  }
  &__upper {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    height: 376px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 6;
    &__right {
      &__title {
        font-size: 5rem;
        font-style: normal;
        font-weight: 700;
        line-height: 78.5%;
        letter-spacing: -3.6px;
        transform: translateY(50px);
        transition: 0.8s;
        opacity: 0;
      }
      &__list {
        width: 600px;
        margin-top: 50px;
        padding: 45px;
        border-radius: 30px;
        border: 4px solid var(--white);
        display: flex;
        justify-content: flex-start;
        gap: 50px;
        transform: translateX(50px);
        transition: 0.8s;
        opacity: 0;
        transition-delay: 0.2s;
        &__column {
          display: flex;
          flex-direction: column;
          gap: 30px;
          &__item {
            padding-left: 45px;
            position: relative;
            display: flex;
            align-items: center;
            font-family: var(--text-ff);
            font-size: 1.125rem;
            font-weight: 600;
            &::before {
              position: absolute;
              content: "";
              top: -3px;
              left: 0;
              width: 30px;
              height: 30px;
              background: url(../images/designListIcon.svg) no-repeat center center / cover;
            }
          }
        }
      }
    }
    &__image {
      &__container {
        margin-top: -300px;
        transition: 0.6s;
        transform: translateY(100px);
        opacity: 0;
      }
    }
  }
  &__lower {
    align-self: flex-start;
    margin-left: 30px;
    .design__upper__image__container {
      display: none;
    }
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    &__title {
      transform: translateY(50px);
      transition: 0.8s;
      opacity: 0;
      font-family: var(--secondary-ff);
      font-size: 110px;
      line-height: 78.5%; /* 86.35px */
      letter-spacing: -4.95px;
    }
    &__info {
      display: flex;
      align-items: center;
      &__button {
        border: 4px solid var(--white);

        margin-right: 90px;
      }
    }
    &__blocks {
      display: flex;
      flex-direction: column;
      gap: 40px;
      width: 830px;
      margin-top: -50px;
    }
    &__block {
      font-style: normal;
      font-weight: 400;
      line-height: 131%;
      font-family: var(--text-ff);
      font-size: 1.25rem;
      width: 470px;

      &:nth-child(1) {
        align-self: flex-end;
        transition-delay: 0.2s;
      }
      &:nth-child(2) {
        align-self: flex-end;
        transition-delay: 0.3s;
        margin-right: 200px;
      }
      &:nth-child(3) {
        align-self: flex-end;
        transition-delay: 0.4s;
        margin-right: 100px;
      }
    }
  }
}

.marketing {
  position: relative;
  z-index: 6;
  color: var(--black);
  padding: 0 0 0;
  &__inner {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 100px;
  }
  &__button {
    position: absolute;
    bottom: 100px;
    right: 30px;
    border: 4px solid var(--black);
  }
  &__column {
    width: 670px;
    &:nth-child(1) {
      .marketing__column__title {
        display: none;
      }
      .marketing__column__image__container:nth-child(1) {
        display: none;
      }
      .marketing__column__image__container:nth-child(5) {
        display: flex;
      }
    }
    &__text {
      font-style: normal;
      font-weight: 400;
      line-height: 131%;
      font-family: var(--text-ff);
      font-size: 1.25rem;
    }
    display: flex;
    flex-direction: column;
    gap: 50px;
    &__title {
      margin-bottom: 50px;
      transform: translateY(50px);
      transition: 0.8s;
      opacity: 0;
    }
    &__subtitle {
      font-size: 5rem;
      font-style: normal;
      font-weight: 700;
      line-height: 78.5%;
      letter-spacing: -3.6px;
      transform: translateY(40px);
      opacity: 0;
      transition: 0.8s;
      transition-delay: 0.2s;
    }
    &__image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      &__container {
        width: 800px;
        height: 800px;
        margin-top: -280px;
        margin-bottom: 30px;
      }
    }
    &__benefits {
      display: flex;
      flex-wrap: wrap;
      gap: 25px;
    }
    &__benefit {
      font-size: 1.5625rem;
      font-style: normal;
      font-weight: 600;
      line-height: 131%;
      font-family: var(--text-ff);
      padding: 25px 40px;
      border-radius: 40px;
      border-radius: 130px;
      border: 2px solid var(--black);
      transition: 0.3s;
      &:hover {
        transform: rotate(2deg);
        box-shadow: 0 0 0 1px;
      }
    }
  }
}

.support {
  &:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background: var(--black);
    z-index: -1;
  }
  position: relative;
  color: var(--white);
  padding: 230px 0 170px;
  border-radius: 0 0 60px 60px;
  margin-top: -60px;
  background: url(../images/supportBg.png) no-repeat center left;
  &__inner {
    display: flex;
    align-items: flex-start;
    width: 100%;
    gap: 60px;
    margin-top: 100px;
  }
  &__info {
    display: flex;
    flex-direction: column;
    width: 100%;
    &__text {
      width: 700px;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 400;
      line-height: 131%;
      font-family: var(--text-ff);
      transform: translateY(50px);
      opacity: 0;
      transition: 0.8s;
      transition-delay: 0.2s;
      margin-top: 20px;
    }
    &__title {
      transform: translateY(50px);
      opacity: 0;
      transition: 0.8s;
      font-family: var(--secondary-ff);
      font-size: 80px;
      font-weight: 400;
      line-height: 78.5%; /* 62.8px */
      letter-spacing: -3.6px;
    }
  }
  &__services {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding-right: 40px;
    gap: 20px;
    transform: translateY(50px);
    opacity: 0;
    transition: 0.8s;
    &__column {
      &__title {
        display: flex;
        align-items: flex-end;
        height: 80px;
        font-size: 4.125rem;
        font-style: normal;
        font-weight: 700;
        letter-spacing: -2.97px;
        text-shadow: -2px 0 var(--black), 0 2px var(--black), 2px 0 var(--black), 0 -2px var(--black);
      }
      &__button {
        height: 80px;
        font-size: 1.875rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-style: normal;
        font-weight: 800;
        line-height: 131%;
        border-radius: 101px;
        border: 4px solid var(--black);
        background: var(--olive);
        color: var(--black);
        width: 350px;
        transition: 0.3s;
        &:hover {
          background: rgb(233, 255, 106);
          transform: rotate(2deg);
        }
      }
      display: flex;
      flex-direction: column;
      gap: 20px;
      &__item {
        width: 350px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 80px;
        border-radius: 202px;
        box-shadow: 0 0 0 1px var(--white);
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 600;
        line-height: 131%;
        font-family: var(--text-ff);
        transition: 0.3s;
        &:hover {
          transform: rotate(2deg);
          box-shadow: 0 0 0 2px;
        }
      }
    }
  }
}

.extra {
  &::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    border-radius: 0 0 60px 60px;
    background: var(--black);
  }
  position: relative;
  z-index: 2;
  margin-top: -60px;
  border-radius: 0 0 60px 60px;
  padding: 60px 0 30px;
  background: var(--white);
  color: var(--black);
  &__title {
    text-align: center;
    transform: translateY(50px);
    opacity: 0;
    transition: 0.8s;
  }
  &__inner {
    display: flex;
    flex-direction: column;
    gap: 50px;
  }

  &__grid {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    &__item {
      transition: 0.8s;
      transform: translateY(50px);
      opacity: 0;
      cursor: pointer;
      &:nth-child(1) {
        grid-area: 1 / 1 / 1 / 2;
        transition-delay: 0.3s;
      }
      &:nth-child(2) {
        grid-area: 1 / 2 / 1 / 4;
        background: var(--black);
        color: var(--white);
        transition-delay: 0.6s;
        .extra__grid__item__services__item {
          border: 2.5px solid var(--white);
        }
      }
      &:nth-child(3) {
        grid-area: 2 / 1 / 2 / 3;
        background: #00777e;
        color: var(--white);
        transition-delay: 0.9s;
        .extra__grid__item__services__item {
          border: 2px solid var(--white);
        }
      }
      &:nth-child(4) {
        transition-delay: 1.2s;
        grid-area: 2 / 3 / 2 / 4;
      }
      display: flex;
      flex-direction: column;
      padding: 50px 45px;
      border-radius: 40px;
      border: 5px solid var(--black);
      gap: 50px;
      &__text {
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 400;
        line-height: 131%;
        font-family: var(--text-ff);
      }
      &__title {
        font-size: 5rem;
        font-style: normal;
        font-weight: 700;
        line-height: 78.5%;
        letter-spacing: -3.6px;
      }
      &__services {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        &__item {
          font-size: 1.5625rem;
          font-style: normal;
          font-weight: 600;
          line-height: 131%;
          padding: 25px 30px;
          border-radius: 130px;
          border: 2px solid var(--black);
          font-family: var(--text-ff);
          transition: 0.3s;
          &:hover {
            transform: rotate(2deg);
            box-shadow: 0 0 0 1px;
          }
        }
      }
    }
  }
}

.contact {
  position: relative;
  margin-top: -60px;
  padding: 0 0 70px;
  display: flex;
  flex-direction: column;
  color: var(--white);
  background: var(--black);

  &__upper {
    display: flex;
    flex-direction: column;
    position: relative;
    &::before {
      position: absolute;
      content: "";
      top: 0;
      right: 0;
      width: 400px;
      height: 400px;
      background: url(../images/contactUpperImage.png) no-repeat center center / cover;
    }
    &__title {
      position: relative;
      transform: translateY(-50px);
      transition: 0.8s;
      opacity: 0;
      font-family: var(--secondary-ff);
      font-size: 80px;
      line-height: 78.5%; /* 62.8px */
      letter-spacing: -3.6px;
      margin-top: 220px;
      &__icon {
        position: absolute;
        bottom: -60px;
        left: 0;
        width: 52px;
        height: 52px;
        transform: rotate(30deg);
        transition: 0.8s;
      }
    }
    &__subtitle {
      color: #fff;
      font-size: 30px;
      font-weight: 700;
      line-height: 78.5%; /* 23.55px */
      letter-spacing: -1.35px;
      align-self: center;
      transform: translateY(30px);
      opacity: 0;
      transition: 0.8s;
      transition-delay: 0.1s;
    }
  }
  &__form {
    &__status {
      text-align: center;
      margin: 10px 0;
    }
    margin-top: 60px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    gap: 25px;
    &__column {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 30px;
      &__input {
        &__block {
          display: flex;
          flex-direction: column;
          gap: 10px;
          &__title {
            padding-left: 20px;
            font-size: 1.5625rem;
            font-style: normal;
            font-weight: 300;
            line-height: 122%;
            font-family: var(--text-ff);
          }
          &__input {
            width: 100%;
            border-radius: 104px;
            border: 2px solid var(--white);
            padding: 20px 25px;
            background: transparent;
            color: var(--white);
            font-family: var(--text-ff);
            font-size: 1.125rem;
            transition: 0.3s;
            &:focus {
              box-shadow: 0 0 0 1px var(--white);
            }
          }
        }
      }
    }
    &__textarea {
      &__container {
        width: 50%;
        height: 200px;
        flex-shrink: 0;
      }
      width: 100%;
      height: 100%;
      border-radius: 40px;
      color: var(--white);
      border: 2px solid var(--white);
      padding: 20px 25px;
      background: transparent;
      margin-top: 10px;
      color: var(--white);
      font-family: var(--text-ff);
      font-size: 1.125rem;
      transition: 0.3s;
      &:focus {
        box-shadow: 0 0 0 1px var(--white);
      }
    }
  }
  &__button {
    width: 45px;
    height: 45px;
    position: relative;
    left: -3px;
    transition: 0.3s;
    &__container {
      margin-left: auto;
      transition: 0.3s;
      &:hover {
        box-shadow: inset 0 0 0 1px var(--black);
        .contact__button {
          transform: rotate(20deg);
        }
      }
      margin-top: 30px;
      width: 100px;
      height: 100px;
      border-radius: 50%;
      background: var(--white);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.footer {
  display: flex;
  color: var(--white);
  flex-direction: column;
  align-items: center;
  background: var(--black);
  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--black);
    border-radius: var(--borderRadius);
  }
  &__policies {
    display: flex;
    justify-content: center;
    gap: 60px;
    width: 100%;
    padding: 30px 0;
    &__link {
      font-family: var(--text-ff);
      font-size: 1.2rem;
      transition: 0.4s;
      cursor: pointer;
      &:hover {
        transform: translateY(-2px);
        transition: 0.4s;
      }
    }
  }
  &__copyright {
    font-size: 1.25;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.9px;
    font-family: var(--text-ff);
  }
  &__contact {
    padding: 35px 100px;
    color: var(--black);
    width: 100%;
    border-radius: 40px;
    background: var(--white);
    display: flex;
    align-items: flex-start;
    height: 270px;
    &__column {
      align-items: center;
      &:nth-child(2) .footer__contact__column__line:nth-child(2) {
        margin-left: -95px;
      }
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 20px;
      &__title {
        font-size: 2.25rem;
        font-style: normal;
        font-weight: 700;
        line-height: 128%;
      }
      &__line {
        display: flex;
        align-items: flex-start;
        gap: 15px;
        &__text {
          font-size: 1.125rem;
          font-style: normal;
          font-weight: 300;
          line-height: 128%;
          font-family: var(--text-ff);
        }
        &__icon {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
  &__logo {
    font-size: 6.18rem;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: -4.455px;
    margin: 50px 0;
    width: 800px;
  }
  &__row {
    margin: 50px 0 30px;
    padding: 0 30px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    &__left {
      &__copyright {
        margin-top: 20px;
        color: #535353;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: -0.72px;
        font-family: var(--text-ff);
      }
    }
    &__right {
      &__text {
        text-align: right;
        margin-bottom: 5px;
        font-family: var(--text-ff);
        font-size: 16px;
        font-weight: 400;
        line-height: 128%; /* 20.48px */
      }
    }
    &__logo {
      &__container {
        display: flex;
        align-items: center;
        gap: 27px;
        font-family: var(--secondary-ff);
        font-size: 50.536px;
        font-weight: 400;
        line-height: 78.5%; /* 39.671px */
        letter-spacing: -2.274px;
      }
    }
    &__item {
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 128%;
      cursor: pointer;
      font-family: var(--secondary-ff);
      transition: 0.4s;
      &:hover {
        transform: translateY(-2px);
        transition: 0.4s;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  :root {
    --containerOffset: 20px;
  }
  // .title {
  //   font-size: 5rem;
  //   letter-spacing: -2px;
  // }

  .menu {
    padding: 25px 30px !important;
    width: 100%;
    border-radius: 0;
    gap: 20px;
    &__services {
      &__title {
        font-size: 1.25rem;
      }
      &__list {
        &__column {
          &__item {
            font-size: 0.85rem;
            padding-left: 25px;
            &::before {
              width: 10px;
              height: 10px;
            }
          }
        }
      }
    }
    &__contacts {
      gap: 20px;
      &__line {
        align-self: flex-start !important;
        &__text {
          font-size: 0.85rem;
        }
        &__icon {
          width: 15px;
          height: 15px;
          margin-top: 1px;
        }
      }
      &__title {
        font-size: 1.25rem;
      }
    }
    &__burger {
      top: 18px;
      right: 34px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 36px;
      gap: 9px;
      transition: 0.6s;
      &__line {
        height: 6px;
      }
    }
  }

  .menuActive {
    transform: translate(0, 0) scale(1);
    .menu__burger {
      &__line {
        &:nth-child(1) {
          transform: translate(1px, 17px) rotate(45deg);
        }
        &:nth-child(2) {
          opacity: 0;
        }
        &:nth-child(3) {
          transform: translate(1px, -13px) rotate(-45deg);
        }
      }
    }
  }

  .header {
    &__logo {
      width: 200px;
      height: 35px;
      padding-bottom: 0;
    }
    &__inner {
      height: 80px;
    }
    &__menu {
      width: 36px;
      gap: 5px;
      &__line {
        height: 6px;
      }
    }
  }

  .banner {
    overflow: visible;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 80px;
    &__bg {
      min-height: 200px;
      height: 200px;
      margin: 20px 0 30px;
      background: url(../images/introBgMobile.png) no-repeat top center / cover;
    }
    &__info {
      display: flex;
      flex-direction: column;
      height: unset;
      gap: 20px;
    }
    &__poster {
      font-size: 1.25rem;
      top: 20px;
      padding: 12px;
      border-radius: 10px;
      border: 2px solid var(--black);
      left: 27%;
    }
    &__title {
      font-size: 50px;
      padding-top: 150px;
      .word:nth-child(2) {
        padding-left: 0;
      }
      &__icon {
        left: 0;
      }
    }
    &__right {
      width: 100%;
      position: relative;
      height: auto;
      align-items: flex-start;
      &__text {
        text-align: left;
        font-size: 1rem;
        align-self: flex-start;
        max-width: 360px;
        width: 100%;
      }
      &__button {
        width: 30px;
        height: 30px;
        &__container {
          height: 80px;
          width: 80px;
          font-size: 1rem;
          position: absolute;
          top: -70px;
          right: 0px;
        }
      }
    }
  }

  .services {
    &__list {
      flex-direction: column;
      &__item {
        min-height: 250px;
        gap: 30px;
        justify-content: unset;
        height: auto;
        &:not(:last-child) {
          width: 100% !important;
          margin-right: 0 !important;
          margin-bottom: -30px;
          border-radius: 30px 30px 0 0;
        }
        &__icon {
          &__container {
            width: 70px;
            height: 70px;
          }
        }
        padding: 30px 20px;
        &__title {
          font-size: 30px;
        }
      }
    }
    &__upper {
      margin: 25px 0;
      padding: 0;
      flex-direction: column;
      align-items: flex-start;
      &__title {
        font-size: 50px;
        padding: 0;
      }
      &__quote {
        margin-top: 20px;
        border-radius: 20px;
        gap: 10px;
        width: auto;
        &__text {
          font-size: 1rem;
        }
        border: 1px solid var(--white);
        padding: 5px 15px 5px 0;
        &__spinner {
          width: 100px;
          height: 100px;
        }
      }
    }
    &__lower {
      gap: 30px;
      padding: 170px 30px 80px;
      background: url(../images/mobile/servicesMobileBg.png) no-repeat center center / cover;

      &__row {
        margin-top: 50px;
        align-items: center;
        gap: 55px;
        flex-direction: column;
        &__item {
          gap: 18px;
          width: 140px;
          align-items: center;
          &__number {
            font-size: 6.7rem;
          }
          &__text {
            font-size: 1rem;
          }
          &__symbol {
            &__container {
              width: 160px;
              height: 110px;
            }
          }
        }
      }
      &__text {
        font-size: 2.5rem;
        width: unset !important;
      }
    }
  }

  .dev {
    padding: 0 0 30px;
    &__upper {
      flex-direction: column;
      &__image {
        &__container {
          width: 100%;
          height: auto;
          order: 1;
          right: 0;
        }
      }
      &__title {
        order: 1;
        margin: 0 0 40px;
      }
    }
    &__row {
      margin-top: 0;
      flex-direction: column;
      margin-bottom: 30px;
    }
    &__column {
      padding: 25px 15px;
      min-height: auto;
      width: 100%;

      &__button {
        display: none;
      }
      &__title {
        font-size: 1.9rem;
        letter-spacing: -1px;
      }
      &__text {
        margin: 25px 0;
        font-size: 0.85rem;
      }
      &__services {
        gap: 25px;
        &__title {
          font-size: 1.9rem;
          letter-spacing: -1px;
        }
        &__list {
          flex-direction: column;
          gap: 30px;
          &__column {
            &__item {
              font-size: 0.85rem;
            }
          }
        }
      }
    }
  }

  .design {
    &::after {
      display: none;
    }
    padding: 60px 0 35px;
    &__inner {
      flex-direction: column;
      align-items: center;
    }
    &__upper {
      height: 176px;
      &__image__container {
        display: none;
        margin-top: 0;
      }
      margin-top: 35px;
      flex-direction: column;
      order: 2;
      &__right {
        margin-top: 30px;
        width: 100%;
        &__list {
          width: auto;
          margin-top: 20px;
          padding: 20px 15px;
          border: 2px solid var(--white);
          flex-direction: column;
          gap: 20px;
          &__column {
            gap: 20px;
            &__item {
              padding-left: 35px;
              font-size: 0.85rem;
              &::before {
                width: 25px;
                height: 25px;
                top: -4px;
              }
            }
          }
        }
        &__title {
          font-size: 1.8rem;
          letter-spacing: -1px;
        }
      }
    }
    &__lower {
      margin-left: 0;
      &__title {
        font-size: 50px;
      }
      &__info {
        margin-left: 0;
        &__button {
          display: none;
        }
      }
      &__block {
        font-size: 0.85rem;
        width: 100% !important;
        margin-right: 0 !important;
      }
      &__blocks {
        margin-top: 20px;
        width: auto;
        align-items: flex-start !important;
        gap: 20px;
      }
    }
  }

  .marketing {
    padding: 0 0 35px;
    &__inner {
      gap: 0;
      flex-direction: column;
      gap: 25px;
    }
    &__button {
      display: none;
    }
    &__column {
      width: unset;
      &:nth-child(1) {
        .marketing__column__title {
          display: flex;
        }

        .marketing__column__image__container:nth-child(5) {
          display: none;
        }
      }
      &:nth-child(2) {
        .marketing__column__title {
          display: none;
        }
      }

      gap: 25px;
      &__text {
        font-size: 0.85rem;
      }
      &__subtitle {
        font-size: 1.8rem;
        letter-spacing: -1px;
      }
      &__image {
        &__container {
          margin-top: 0;
          margin-bottom: 0;
          width: auto;
          height: auto;
          margin-bottom: auto;
        }
      }
      &__benefits {
        gap: 15px;
      }
      &__benefit {
        padding: 15px 25px;
        font-size: 0.85rem;
      }
    }
  }

  .support {
    padding: 60px 0 60px;
    &__inner {
      flex-direction: column;
      gap: 20px;
    }
    &__info {
      font-size: 0.85rem;
      &__title {
        font-size: 50px;
      }
      &__text {
        width: auto;
      }
    }
    &__services {
      padding-right: 0;
      align-items: center;
      flex-direction: column;
      width: 100%;
      margin-top: 10px;
      &__column {
        width: 100%;
        &__title {
          font-size: 1.8rem;
          width: 100%;
          justify-content: center;
          height: auto;
        }
        &__button {
          display: none;
        }
        &__item {
          width: unset;
        }
      }
    }
  }

  .extra {
    margin-top: -20px;
    &::before {
      display: none;
    }
    padding: 0 0 35px;
    &__grid {
      display: flex;
      flex-direction: column;
      &__item {
        padding: 24px 18px;
        gap: 20px;
        border: 2.5px solid var(--black);
        border-radius: 20px;
        &__title {
          font-size: 1.8rem;
          letter-spacing: -1px;
        }
        &__text {
          font-size: 0.85rem;
        }
        &__services {
          gap: 15px;
          &__item {
            padding: 15px 25px;
            font-size: 0.85rem;
          }
        }
      }
    }
  }

  .contact {
    padding: 90px 0 30px;
    background: var(--black);

    &__upper {
      &::before {
        display: none;
      }
      display: flex;
      gap: 20px;
      &__title {
        font-size: 50px;
        margin-top: 70px;
        &__icon {
          top: -70px;
        }
      }
      &__subtitle {
        font-size: 25px;
        justify-content: flex-start;
        width: 100%;
        letter-spacing: -1px;
      }
    }
    &__form {
      margin-top: 25px;
      flex-direction: column;
      gap: 20px;
      &__column {
        gap: 20px;
        &__input__block {
          &__title {
            font-size: 20px;
          }
          &__input {
            font-size: 16px;
          }
        }
      }
      &__textarea {
        &__container {
          width: 100%;
        }
      }
    }
    &__button {
      &__container {
        margin-top: 70px;
        align-self: center;
        margin-right: 0;
        width: 70px;
        height: 70px;
      }
    }
  }

  .footer {
    &__contact {
      gap: 15px;
      padding: 25px 35px;
      flex-direction: column;
      border-radius: 20px;
      height: auto;
      &__column {
        gap: 15px;
        &:nth-child(2) .footer__contact__column__line:nth-child(2) {
          margin-left: -70px;
        }
        &__title {
          font-size: 1.6rem;
        }
        &__line {
          &__text {
            font-size: 0.85rem;
          }
        }
      }
    }
    &__policies {
      flex-direction: column;
      gap: 10px;
      padding: 20px 0;
      align-items: center;
      &__link {
        font-size: 0.9rem;
      }
    }
    &__logo {
      font-size: 2.5rem;
      width: 320px;
      margin: 50px 0 10px;
    }
    &__row {
      margin-top: 25px;
      gap: 20px;
      flex-direction: column;
      align-items: center;
      &__left {
        &__copyright {
          text-align: center;
        }
        margin-bottom: 20px;
      }
      &__item {
        text-align: center;
      }
      &__right {
        margin-bottom: 30px;
        opacity: 0.5;
        svg {
          transform: scale(0.8);
        }
        &__text {
          text-align: center;
          margin-bottom: 0;
        }
      }
      &__column:nth-child(2) {
        margin-top: -20px;
      }
      &__column:nth-child(3) {
        margin-top: -20px;
      }
    }
  }
}

// @media screen and (max-width: 600px) {
//   .title {
//     font-size: 4rem;
//   }
// }
